// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rS d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_rT d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_rV d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_rW d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_rX d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_rY d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_rZ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_r0 z_r0";
export var heroExceptionNormal = "t_r1 z_r1";
export var heroExceptionLarge = "t_r2 z_r2";
export var Title1Small = "t_r3 z_r3 z_ss z_st";
export var Title1Normal = "t_r4 z_r4 z_ss z_sv";
export var Title1Large = "t_r5 z_r5 z_ss z_sw";
export var BodySmall = "t_r6 z_r6 z_ss z_sM";
export var BodyNormal = "t_r7 z_r7 z_ss z_sN";
export var BodyLarge = "t_r8 z_r8 z_ss z_sP";