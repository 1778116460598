// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_rq d_fp d_bG d_dv";
export var alignLeft = "s_qh d_fp d_bG d_dv";
export var alignDiscCenter = "s_rr d_fq d_bD d_dw";
export var alignCenter = "s_bP d_fq d_bD d_dw";
export var alignDiscRight = "s_rs d_fr d_bH d_dx";
export var alignRight = "s_qj d_fr d_bH d_dx";
export var footerContainer = "s_rt d_dW d_bW";
export var footerContainerFull = "s_rv d_dT d_bW";
export var footerHeader = "s_kf d_kf";
export var footerTextWrapper = "s_rw d_w";
export var footerDisclaimerWrapper = "s_km d_km d_ck";
export var badgeWrapper = "s_rx d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "s_ry d_bz d_bJ d_bN d_bL";
export var wide = "s_rz d_cy";
export var right = "s_rB d_bK";
export var line = "s_fk d_fl d_cv";
export var badgeDisclaimer = "s_rC d_bC d_bP d_bJ";
export var badgeContainer = "s_rD d_bz d_bH d_bP";
export var badge = "s_rF";
export var padding = "s_rG d_c7";
export var end = "s_rH d_bH";
export var linkWrapper = "s_rJ d_dB";
export var link = "s_mC d_dB";
export var colWrapper = "s_rK d_cx";
export var consent = "s_f d_f d_bC d_bP";
export var disclaimer = "s_rL d_bz d_bJ";
export var media = "s_rM d_bx d_dy";
export var itemRight = "s_rN";
export var itemLeft = "s_rP";
export var itemCenter = "s_rQ";
export var exceptionWeight = "s_rR z_sV";